import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Heading, VStack, Text } from "@chakra-ui/react";
import * as styles from "../../styles/global.module.css";

import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const Shipping: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPage(
        filter: { slug: { eq: "shipping-refund-and-replacement-policy" } }
      ) {
        nodes {
          id
          title
          content
        }
      }
    }
  `);

  const page = data.allWpPage.nodes[0];

  return (
    <>
      <Layout>
        <Box maxW="1280px" margin="0px auto" pt="120px" pb="80px" px="20px">
          <VStack pb="48px">
            <Box w="1.5rem" height="0.13rem" bg="red.500" />
            <Box>
              <Heading as="h1" fontWeight="400">
                {page.title}
              </Heading>
            </Box>
          </VStack>
          <Box
            className={styles.shippingReturnPolicyContent} // Use the scoped class name
            dangerouslySetInnerHTML={{ __html: page.content }}
            textAlign="left"
          />
        </Box>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Doctor Medica - Shipping, Refund and Replacement Policy"
    description="Doctor Medica offers a website usage policy that should be reviewed by website visitors prior to the use of the website."
  />
);

export default Shipping;
